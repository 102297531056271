.colored_main {
  width: 100%;
  height: 40vw;
  background: #f3f3fa;
  margin-top: 5vw;
}

.test_manual_width{
  width: 80% !important;
  margin-left: 10%;
}


.testimonial_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1vw;
}

.strip_3_div {
  margin-top: 2vw;
}

.strip_3_img{
    width: 22vw;
}

.testimonial_heading {
  color: #000;
  font-family: Merriweather;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.256px;
}

.client_1 {
  width: 25vw !important;
  height: 11.5vw;
  border: 1px solid #979797;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1vw;
}

.my_cards{
    display: flex;
    width: 75vw !important;
    align-items: center !important;
    justify-content: center !important;
    /* margin-left: -25vw !important; */
    gap: 2vw !important;
}




.client_names{
    color: var(--dark-blue, #091638);
font-family: Merriweather;
font-size: 1.7vw;
font-style: normal;
font-weight: 700;
line-height: 2vw; /* 88.32% */
margin-top: 1.7vw;
}

.client_1_img{
    width: 4vw;
}

.client_txt{
    color: #979797;
font-family: Merriweather;
font-size: 1vw;
font-style: normal;
font-weight: 400;
line-height: 1vw;
letter-spacing: -0.377px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}

.client_2{
    width: 25vw !important;
    height: 11.5vw;
    border: 1px solid #979797;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1vw;
    background-color: #000;
    color: #fff !important;
}

.client2_name{
    color:white;
    font-family: Merriweather;
    font-size: 1.7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 2vw; /* 88.32% */
    margin-top: 1.7vw;
}

.feedback_twxt{
    padding: 0.5vw;
    width: 23.5vw !important;
height: 13vw;
/* border: 1px solid black; */
display: flex;
align-items: center;
justify-content: center;
margin-top: 2vw !important;
margin-bottom: 2vw !important;
/* margin-left: 1.5vw; */
color: var(--dark-blue, #091638);
font-family: Merriweather;
font-size: 1.1vw;
font-style: normal;
font-weight: 700;
line-height: 136.5%; /* 30.03px */
}

.text_para{
  width: 23vw;
  color: var(--dark-blue, #091638);
font-family: Merriweather;
font-size: 1.2vw;
font-style: normal;
font-weight: 700;
line-height: 136.5%; /* 30.03px */
}


@media only screen and (max-width: 500px){
  .colored_main {
    width: 100%;
    height: 50vw;
    background: #f3f3fa;
    margin-top: 5vw;
  }
  
}