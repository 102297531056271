.top_two_components{
    background-image: url(./../../Assets/housee.png);
    width: 100%;
    height: 52vw;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative !important;
}


@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");


.buyer_full_width {
  width: 100%;
  height: auto;
  position: relative !important;

}

.buyer_content {
  margin-top: 6vw ;
  width: 76%;
  margin-left: 12%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column !important;
  gap: 5vw;
  /* position: relative !important; */

}

.america_heading {
  color: #ffac12;
  font-family: Merriweather;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.below_america_heading {
  color: #fff;
  font-family: Merriweather;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3vw;
}
.offer_form_div {
  background-color: white;
  width: 68vw;
  height: 7vw;
  position: absolute !important;
  bottom: 0 !important;
  margin-left: 16vw !important;

}

.flex_form {
  height: 7vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1vw;
}

.my_form_button {
    margin-right: 3vw;
    background-color: #fff !important;
  fill: #fff;
  stroke-width: 1px;
  stroke: #4764c3;
  border: 1px solid blueviolet;
  border-radius: 0.3vw;
  width: 10vw;
  height: 3vw;
  color: #000;
  font-family: Merriweather;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.503px;
}

.yarrow_img {
  width: 1.5vw;
  margin-left: 0.5vw;
}

.my_form_inputs {
    background-color: #fff !important;

  display: flex;
  flex-direction: column;
  justify-content: center;

  fill: #fff;
  /* stroke-width: 1px; */
  stroke: #4764c3;

  outline: none;
  width: 9vw;
  height: 3vw;
 border: 1px solid blueviolet;
 border-radius: 0.3vw;
}

.my_form_input {
    border: 1px solid blueviolet;
    border-radius: 0.3vw;
    background-color: #fff !important;

  display: flex;
  flex-direction: column;
  justify-content: center;

  fill: #fff;
  stroke-width: 1px;
  stroke: #4764c3;
outline: none;
  width: 11vw;
  height: 3vw;
}


::placeholder {
    padding-left: 1.5vw;
    color: #FFAC12;
    font-family: Lato;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;

  }


  @media only screen and (max-width: 767px){
    .top_two_components{
        background-image: url(./../../Assets/housee.png);
        width: 100%;
        height: 82vw !important;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
    }

    .buyer_content {
        margin-top: 0vw  !important;
    }
.offer_form_div {
    background-color: white;
    width: 65vw !important;
    height: 7vw;
  }
  
  .flex_form {
    height: 7vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1vw;
  }
  
  .my_form_button {
      margin-right: 3vw;
      background-color: #fff !important;
    fill: #fff;
    stroke-width: 1px;
    stroke: #4764c3;
    border: 1px solid blueviolet;
    border-radius: 0.3vw;
    width: 13vw;
    height: 4vw;
    color: #000;
    font-family: Merriweather;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.503px;
  }
  
  .yarrow_img {
    width: 1.5vw;
    margin-left: 0.5vw;
  }
  
  .my_form_inputs {
      background-color: #fff !important;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    fill: #fff;
    /* stroke-width: 1px; */
    stroke: #4764c3;
  
    outline: none;
    width: 11vw !important;
    height: 4vw !important;
   border: 1px solid blueviolet;
   border-radius: 0.3vw;
   padding-bottom: 1vw;

  }
  
  .my_form_input {
      border: 1px solid blueviolet;
      border-radius: 0.3vw;
      background-color: #fff !important;
      padding-bottom: 1vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
  
    fill: #fff;
    stroke-width: 1px;
    stroke: #4764c3;
  outline: none;
    width: 13vw;
    height: 4vw;
  }
  
  
  ::placeholder {
      padding-left: 1.5vw;
      color: #FFAC12;
      font-family: Lato;
      font-size: 1.4vw;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
  
    }
  

  }


  @media only screen and (max-width: 500px){
    .my_form_inputs {
       
     padding-bottom: 2vw;
  
    }
    
    .my_form_input {
      
        padding-bottom: 2vw;

    }
    
    .top_two_components{
        background-image: url(./../../Assets/housee.png);
        width: 100%;
        height: 72vw !important;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
    }
  }


  
  @media only screen and (max-width: 991px){
    .top_two_components{
        background-image: url(./../../Assets/housee.png);
        width: 100%;
        height: 80vw;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
    }
}



@media only screen and (max-width: 640px){
  .top_two_components{
      background-image: url(./../../Assets/housee.png);
      width: 100%;
      height: 90vw !important;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative !important;
  }
}

@media only screen and (max-width: 550px){
  .top_two_components{
      background-image: url(./../../Assets/housee.png);
      width: 100%;
      height: 120vw !important;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative !important;
  }
}


@media only screen and (max-width: 450px){
  .top_two_components{
      background-image: url(./../../Assets/housee.png);
      width: 100%;
      height: 140vw !important;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative !important;
  }
}

@media only screen and (max-width: 360px){
  .top_two_components{
      background-image: url(./../../Assets/housee.png);
      width: 100%;
      height: 150vw !important;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative !important;
  }
}