@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");


.main_steps_div {
  width: 100%;
  height: 600px;
}

.steps_content {
  margin-top: 7vw !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15vw;
}

.steps_right_div {
  margin-top: -5vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1vw;
}

.step_text {
  color: #000;
  font-family: Merriweather;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
margin-bottom: 2vw;
}

.meeting_img{
    width: 40vw;
}

.strip_1_class{
    width: 11vw;
}

.circle_text_flex{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;
}

.circles_text{
    color: var(--text-color, #141B2D);
font-family: Merriweather;
font-size: 1.4vw;
font-style: normal;
font-weight: 700;
line-height: 195.5%; /* 46.92px */
}

.circle_imgs{
  width: 6.5vw;
}

@media only screen and (max-width: 767px){
    .steps_content {
        margin-top: 7vw !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10vw;
        flex-direction: column !important;
      }

      .step_text {
        color: #000;
        font-family: Merriweather;
        font-size: 6vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      margin-bottom: 2vw;
      }
      
      .meeting_img{
          width: 52vw;
      }
      
      .strip_1_class{
          width: 22vw;
      }
      
      .circles_text{
          color: var(--text-color, #141B2D);
      font-family: Merriweather;
      font-size: 2vw;
      font-style: normal;
      font-weight: 700;
      line-height: 195.5%; /* 46.92px */
      }
      .steps_right_div {
        margin-bottom: 5vw;
       }

}

@media only screen and (max-width: 400px){
 
  .step_text {
    margin-top: -2vw !important;
  }
}